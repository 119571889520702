// ============================
//     Card css start
// ============================

.card {
  box-shadow: $card-shadow;
  transition: box-shadow 0.2s ease-in-out;
  .card-header {
    border-bottom: 1px solid $border-color;
    .float-end .btn {
      margin: -6px 0;
    }
    h5 {
      margin-bottom: 0;
      color: $headings-color;
      font-size: 16px;
      font-weight: 600;

      + p,
      + small {
        margin-top: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &:not(.border-0) {
      h5 {
        position: relative;
        // &:after {
        //   content: "";
        //   height: 30px;
        //   width: 3px;
        //   background: $primary;
        //   position: absolute;
        //   left: -25px;
        //   top: -5px;
        //   border-radius: 0 3px 3px 0;
        // }
      }
    }

    .card-header-right {
      right: 10px;
      top: 10px;
      float: right;
      padding: 0;
      position: absolute;

      @media only screen and (max-width: 575px) {
        display: none;
      }

      .dropdown-menu {
        margin-top: 0;

        li {
          cursor: pointer;

          a {
            font-size: 14px;
            text-transform: capitalize;
          }
        }
      }

      .btn.dropdown-toggle {
        border: none;
        background: transparent;
        box-shadow: none;
        color: #888;

        i {
          margin-right: 0;
        }

        &:after {
          display: none;
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      // custom toggler
      .btn.dropdown-toggle {
        border: none;
        background: transparent;
        box-shadow: none;
        padding: 0;
        width: 20px;
        height: 20px;
        right: 8px;
        top: 12px;

        &.mobile-menu span {
          background-color: #888;
          height: 2px;
          border-radius: 5px;

          &:after,
          &:before {
            border-radius: 5px;
            height: 2px;
            background-color: #888;
          }
        }
      }

      .nav-pills {
        padding: 0;
        box-shadow: none;
        background: transparent;
      }
    }
  }

  .card-footer {
    transition: box-shadow 0.2s ease-in-out;
    border-top: 1px solid $border-color;
  }
  .card-img-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  &:hover {
    .card-footer[class*="bg-"] {
      box-shadow: none;
    }
  }

  @each $color, $value in $theme-colors {
    &.bg-#{$color} {
      box-shadow: 0 9px 9px -1px transparentize($value, 0.7);
    }
  }
}

@include media-breakpoint-down(sm) {
  .card {
    margin-bottom: 0;
  }
}

// Card css end
